<template>
    <div>
        <!--begin::Content header-->
        <!--end::Content header-->

        <!--begin::Signin-->
        <div class="login-form login-signin">
            <div class="text-center mb-10 mb-lg-20">
                <h3 class="font-size-h1">Effettua l'accesso</h3>
                <p class="text-muted font-weight-semi-bold">
                    Per assistenza tecnica scrivere a <a
                        href="mailto:supporto-tecnico@acsi.it">supporto-tecnico@acsi.it</a>.<br>
                    Un nostro tecnico sarà a vostra disposizione.
                </p>
            </div>

            <!--begin::Form-->
            <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert alert-primary">
                    <div class="alert-text">
                        Utilizza le tue <strong>credenziali</strong> per effettuare l'<strong>accesso</strong>.
                    </div>
                </div>

                <div role="alert" v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                    <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                    </div>
                </div>

                <b-form-group id="input-group-1" label="" label-for="input-1">
                    <label for="login-username">Username:</label>
                    <b-form-input class="form-control form-control-solid h-auto py-5 px-6" id="login-username"
                        name="login-username" v-model="$v.form.username.$model" :state="validateState('username')"
                        aria-describedby="input-1-live-feedback" placeholder="Inserisci il tuo username"></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">
                        Lo username è obbligatorio.
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-2" label="" label-for="input-2">
                    <label for="login-password">Password:</label>
                    <b-form-input class="form-control form-control-solid h-auto py-5 px-6" type="password"
                        id="login-password" name="input-2" v-model="$v.form.password.$model"
                        :state="validateState('password')" aria-describedby="input-2-live-feedback"
                        placeholder="Inserisci la tua password"></b-form-input>

                    <b-form-invalid-feedback id="input-2-live-feedback">
                        La Password è obbligatoria.
                    </b-form-invalid-feedback>
                </b-form-group>

                <button ref="kt_login_signin_submit"
                    class="btn btn-primary btn-block font-weight-bold px-9 py-4 my-3 font-size-3">
                    {{ $t("AUTH.LOGIN.BUTTON") }}
                </button>

                <!--begin::Action-->
                <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <router-link custom id="kt_login_forgot" class="text-dark-60 text-hover-primary my-3 mr-2"
                        :to="{ name: 'recover_password' }">
                        Recupera la Password
                    </router-link>

                    <router-link custom id="kt_login_forgot" class="text-dark-60 text-hover-primary mt-5 my-3 mr-2"
                        :to="{ name: 'recover_username' }">
                        Recupera lo Username
                    </router-link>
                </div>
                <!--end::Action-->

                <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <span class="font-weight-bold font-size-3 text-dark-60">
                        Non hai ancora un account?
                    </span>
                    <router-link custom class="font-weight-bold font-size-3 ml-2" :to="{ name: 'register' }">
                        Registrati!
                    </router-link>
                </div>
            </b-form>
            <!--end::Form-->
        </div>
        <!--end::Signin-->
    </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
    padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    name: "login",
    data() {
        return {
            form: {
                username: "",
                password: ""
            }
        };
    },
    validations: {
        form: {
            username: {
                required
            },
            password: {
                required,
                minLength: minLength(3)
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                username: null,
                password: null
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            const username = this.$v.form.username.$model;
            const password = this.$v.form.password.$model;

            // clear existing errors
            this.$store.dispatch(LOGOUT);

            // set spinner to submit button
            const submitButton = this.$refs["kt_login_signin_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store
                .dispatch(LOGIN, { username, password })
                // go to which page after successfully login
                .then(() => this.$router.push({ name: "dashboard" }));

            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        }
    },
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        })
    }
};
</script>
